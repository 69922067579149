<template>
  <div class="myFooter-wrap" v-show="showFooter">
    <div class="myFooter">
      <div class="footer-title">{{text}}</div>
<!--      <div class="icp">本网站由 <a href="https://poetize.cn" target="_blank">寻国记</a> 强力支持</div>-->
      <div class="icp">备案号:<a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2021000716号-3</a> </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      showFooter: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        text:'',
        guShi: {
          "content": "",
          "origin": "",
          "author": "",
          "category": ""
        },
      }
    },
    created() {
      // console.log(text)
      // this.getTianGou()
    },
    methods: {
      getTianGou(){
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('get', this.$constant.jinrishici);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            that.guShi = JSON.parse(xhr.responseText);
            that.text = that.guShi.hitokoto;
          }
        };
        xhr.send();
      }
    }
  }
</script>

<style scoped>
  .myFooter-wrap {
    user-select: none;
    animation: hideToShow 2s;
  }

  .myFooter {
    border-radius: 1.5rem 1.5rem 0 0;
    background: var(--gradientBG);
    text-align: center;
    color: var(--white);
    background-size: 300% 300%;
    animation: gradientBG 10s ease infinite;
  }

  .footer-title {
    padding-top: 10px;
    font-size: 16px;
  }

  .icp, .icp a {
    color: var(--maxGreyFont);
    font-size: 13px;
  }

  .icp {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .icp a {
    text-decoration: none;
    transition: all 0.3s;
  }

  .icp a:hover {
    color: var(--themeBackground);
  }

</style>
